import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import Image from "../ImageQuerys/SuccessImages";
import arrowUp from "../../images/svg/arrows_up.svg";
import Heading from "../../components-v2/Base/HeadingBuilder";
import PrimaryCta from "../Buttons/primary-cta";

const { Title, Paragraph } = Typography;

const StorySlide = (props) => {
  const {
    image,
    companyIs,
    companyName,
    companyLogo,
    address,
    owner,
    website,
    description,
    logoClassName,
    ctaTitle,
    url,
    info,
    logoStyles,
  } = props;

  const urlSpacing = url ? 48 : 24;
  return (
    <Row className="success-slide" gutter={[32, 32]}>
      <Col lg={9} md={24} flex={1}>
        <Image
          imageName={image}
          style={{ height: "100%", borderRadius: "8px" }}
        />
      </Col>
      <Col lg={10} md={24}>
        <div className="success-slide__content">
          <Row>
            <Col span={18}>
              <Title level={3}>{companyName}</Title>
              <Heading
                level={4}
                injectionType={4}
                className="text-white"
                style={{ color: "#fff" }}
              >
                {companyIs}
              </Heading>
            </Col>
            <Col span={6}>
              <Image
                imageName={companyLogo}
                style={logoStyles}
                className={`success-slide__content--logo ${logoClassName}`}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={4}>
              <span className="list-descriptor">Location</span>
            </Col>
            <Col xs={24} md={20}>
              <Paragraph className="list-content">{address}</Paragraph>
            </Col>
            <Col xs={6} md={4}>
              <span>Owner</span>
            </Col>
            <Col xs={24} md={20}>
              <Paragraph className="list-content">{owner}</Paragraph>
            </Col>
            <Col xs={6} md={4}>
              <span>Website</span>
            </Col>
            <Col xs={24} md={20}>
              <Paragraph className="list-content">{website}</Paragraph>
            </Col>
          </Row>
          <p className="success-slide__content--description">{description}</p>
          {url && (
            <PrimaryCta
              className="mt-6 font-bold"
              ctaTitle={ctaTitle}
              target={url}
            />
          )}
        </div>
      </Col>
      <Col lg={6} md={24}>
        <Row gutter={[0, urlSpacing]}>
          {info.map((square, idx) => (
            <Col key={idx.toString()} lg={24} md={12} flex={1}>
              <div className="success-slide__info">
                {square.numbers && (
                  <Title
                    level={4}
                    className={
                      square.isAuto && "whitespace-pre-line special-spacing"
                    }
                  >
                    {square.numbers}
                  </Title>
                )}
                <img src={arrowUp} alt="" />
                <p
                  className={`success-slide__info-p ${
                    square.customPara ? square.customPara : "mt-[110px]"
                  }`}
                >
                  {square.numberType}
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

StorySlide.propTypes = {
  companyName: PropTypes.string,
  companyIs: PropTypes.string,
  companyLogo: PropTypes.string,
  address: PropTypes.string,
  owner: PropTypes.string,
  website: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  logoClassName: PropTypes.string,
  ctaTitle: PropTypes.string,
  logoStyles: PropTypes.objectOf(PropTypes.any),
  info: PropTypes.arrayOf(PropTypes.any),
};
StorySlide.defaultProps = {
  companyName: "This is the title",
  companyIs: "",
  companyLogo: "",
  address: "",
  owner: "",
  website: "",
  url: "",
  description: "This is the description",
  image: "",
  logoClassName: "",
  ctaTitle: "",
  logoStyles: {},
  info: [],
};

export default StorySlide;
