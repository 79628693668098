import React from "react";
import { viewports, colors, fontFamily } from "../../style-vars";

const { mdOrSmaller } = viewports;
const { black, white, success } = colors;

const SuccessStoriesStyles = () => (
  <style jsx global>{`
    .success-stories {
      position: relative;
      max-width: 95%;
      margin: auto;
      border-radius: 8px;
      padding: 64px 100px 100px 100px;
      background-color: #f6f8fb;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .success-slide__info h4.ant-typography .special-spacing {
      line-height: 32px !important;
    }
    @media ${mdOrSmaller} {
      .success-stories {
        max-width: 100%;
        padding: 70px 30px;
      }
    }
    .success-stories__texts h2,
    .success-stories__texts h4 {
      text-align: center;
    }
    .success-stories__texts h2 {
      font-weight: 800 !important;
      margin-top: 8px !important;
      margin-bottom: 40px !important;
    }
    .success-stories__texts h2:first-letter {
      text-transform: uppercase !important;
    }
    .success-stories__texts h4 {
      font-size: 18px;
      font-weight: 800 !important;
      color: ${black} !important;
    }
    .success-stories .btn-right {
      transform: translateY(14px);
    }
    .success-slide__content {
      border-radius: 8px;
      padding: 40px 32px;
      height: 100%;
      background: ${black};
      color: ${white} !important;
    }
    .success-slide__content h3,
    .success-slide__content h4 {
      color: ${white} !important;
    }
    .success-slide__content h4 {
      font-size: 16px;
      text-align: left;
      margin-top: 20px;
    }
    .success-slide__content h5 {
      font-weight: 300;
      margin-top: 8px;
      margin-bottom: 24px;
    }
    .success-slide__content--logo {
      width: 100px;
      height: 100px;
    }
    @media ${mdOrSmaller} {
      .success-slide__content--logo {
        border-radius: 50%;
        width: 75px;
        height: 75px;
      }
    }
    .success-slide__content--description {
      font-weight: 400 !important;
      color: ${white} !important;
      font-size: 16px !important;
      line-height: 2.2;
      margin: 32px 0 48px 0 !important;
      font-family: ${fontFamily} !important;
      //max-height: 200px !important;
    }
    .success-slide .list-descriptor {
      font-size: 14px;
      color: ${white};
      font-weight: 400 !important;
    }
    .success-slide .list-content {
      color: ${white} !important;
      margin-bottom: 0 !important;
      font-weight: 700 !important;
    }
    .success-slide__info {
      border-radius: 8px;
      background-color: ${white};
      padding: 32px;
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    @media ${mdOrSmaller} {
      .success-slide__info {
        margin: 0 8px;
        width: 95% !important;
      }
    }
    .success-slide__info img {
      position: absolute;
      right: 24px;
      top: 24px;
    }
    .success-slide__info h4.ant-typography {
      font-size: 26px;
      text-align: left;
      color: ${success} !important;
      font-weight: 800 !important;
      line-height: 32px !important;
    }
    .success-slide__info-p {
      margin-bottom: 0;
      color: #748093 !important;
      font-weight: 400 !important;
      font-size: 16px;
    }

    p.success-slide__content--description {
      margin-bottom: 0 !important;
    }
  `}</style>
);

export default SuccessStoriesStyles;
