import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import Carousel from "nuka-carousel";
import StorySlide from "./StorySlide";
import chevron from "../../images/svg/chevron-right-blue.svg";

import back from "../../images/global_assets/abstract_pattern.png";
import SuccessStoriesStyles from "./SuccessStoriesStyles";

const { Title } = Typography;

const renderDotControls = ({
  currentSlide,
  slideCount,
  nextSlide,
  goToSlide,
}) => {
  return (
    <div
      className="slider-control-bottomcenter"
      style={{
        position: "absolute",
        bottom: "-45px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <ul
        style={{
          position: "relative",
          margin: "0px",
          top: "40px",
          padding: "0px",
          display: "flex",
        }}
      >
        {slideCount > 1 &&
          [...Array(slideCount)].map((sc, i) => (
            <li
              style={{ listStyleType: "none", display: "inline-block" }}
              key={i.toString() + 1}
            >
              <div
                style={{
                  border: "none",
                  width: currentSlide === i ? "12px" : "8px",
                  height: currentSlide === i ? "12px" : "8px",
                  background: currentSlide === i ? "#1769FF" : "#C4C4C4",
                  borderRadius: "50%",
                  margin:
                    currentSlide === i ? "0 0 0 12px" : "2.5px 3px 0 12px",
                }}
                onClick={() => goToSlide(i)}
                onKeyPress={() => goToSlide(i)}
                role="button"
                tabIndex="0"
                aria-label="next-slide"
              />
            </li>
          ))}
      </ul>
      {slideCount > 1 && (
        <button
          type="button"
          className="btn-right"
          onClick={nextSlide}
          aria-label="Next Slide"
        >
          <img src={chevron} alt="" />
          <p className="eye-not-visible">Next Slide</p>
        </button>
      )}
    </div>
  );
};

const SuccessStories = (props) => {
  const { className, sectionData, slides, styles } = props;

  return (
    <section
      className={`success-stories ${className}`}
      style={{ backgroundImage: `url(${back})`, ...styles }}
    >
      <Row className="success-stories__texts">
        <Col span={24}>
          {sectionData.subTitle && (
            <p className="mb-2 text-[18px] text-black mx-auto text-center">
              {sectionData.subTitle}
            </p>
          )}

          <Title level={2}>
            {sectionData.title}
            <span className="blue_dot">.</span>{" "}
          </Title>
        </Col>
        {slides.length > 1 ? (
          <Carousel
            slidesPerPage={1}
            enableKeyboardControls
            cellSpacing={20}
            dragging
            wrapAround
            renderBottomCenterControls={(config) => renderDotControls(config)}
            renderCenterLeftControls={null}
            renderCenterRightControls={null}
          >
            {slides.map((slide, idx) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <StorySlide key={idx.toString()} {...slide} />
            ))}
          </Carousel>
        ) : (
          <StorySlide {...slides[0]} />
        )}
      </Row>
      <SuccessStoriesStyles />
    </section>
  );
};

SuccessStories.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  slides: PropTypes.arrayOf(PropTypes.any),
  styles: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
};
SuccessStories.defaultProps = {
  sectionData: "",
  slides: "",
  styles: {},
  className: "",
};

export default SuccessStories;
